export const stringsLocaleSelectorPage = {
  metaTags: {
    title: 'Swapfiets - Your bike for a fixed monthly fee.',
    description:
      'Get an always working bike with a repair service for a fixed monthly fee. Electric bikes and scooters are available. Flexible subscription. You cycle, we repair.',
  },
  headingMain: `Welcome to Swapfiets \nBefore we start the ride.`,
  locale: {
    title: 'Select country and language',
    country: 'Select country',
    language: 'Select language',
    submit: 'Select',
  },
};
