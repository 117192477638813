import { useRouter } from 'next/router';
import React from 'react';
import { H1, P } from '@/components/atoms/Typography/Typography';
import { IconBrandLegacy } from '@/components/Icons/IconBrandLegacy';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import { LocaleSelect } from '@/components/organisms/LocaleSelect';
import { stringsLocaleSelectorPage } from '@/containers/LocaleSelectorPage/strings.localeSelectorPage';
import { PageHead } from '@/containers/PageLayout/components/PageHead/PageHead';
import { countriesData } from '@/data/countries';
import { languagesData } from '@/data/languages';
import { navigateToNewLocale } from '@/utils/navigate/navigateToNewLocale';
import styles from './LocaleSelectorPage.module.scss';

export const LocaleSelectorPage: React.FC = () => {
  const router = useRouter();

  return (
    <>
      <PageHead
        title={stringsLocaleSelectorPage.metaTags.title}
        description={stringsLocaleSelectorPage.metaTags.description}
      />
      <div className={styles.container} data-test-id="localeSelectorPage">
        <div className={styles.left}>
          <CloudinaryImage
            src="/members_web/page/select_country.jpg"
            alt="Swapfiets User"
            layout="fill"
            objectFit="cover"
            sizes={'(max-width: 2000px) 100vw, ' + '60vw'}
            quality={90}
            placeholderPriority={true}
            className={styles.image}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.selectorContainer}>
            <IconBrandLegacy className={styles.logo} />
            <H1>{stringsLocaleSelectorPage.headingMain}</H1>
            <P>{stringsLocaleSelectorPage.locale.title}</P>
            <LocaleSelect
              countries={countriesData}
              languages={languagesData}
              portal={false}
              changeLocale={({ country, language }) => {
                navigateToNewLocale({ language, country, router });
              }}
              fieldLabels={{
                country: stringsLocaleSelectorPage.locale.country,
                language: stringsLocaleSelectorPage.locale.language,
              }}
              submitLabel={stringsLocaleSelectorPage.locale.submit}
            />
          </div>
        </div>
      </div>
    </>
  );
};
